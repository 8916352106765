import Api from './../utils/newapimanager';
import list from './apiconstant';
import { responseAxiosHOF } from '../utils/api';

// const api = new Api();
const api = Api;

export const serviceRegisterPhoneNumber = ({ phone, code }) =>
	api.post(list.registerPhonenumber, {
		phone,
		code,
	});

export const serviceLoginPhoneNumber = ({ phone, code }) =>
	api.post(list.loginPhonenumber, {
		phone,
		code,
	});

export const serviceOtpRegister = ({ phone, code, otp }) =>
	api.post(list.registerOtp, { phone, code, otp });

export const serviceOtpLogin = ({ phone, code, otp }) =>
	api.post(list.loginOtp, { phone, code, otp });

export const serviceVerifyOtp = ({ otp }) => api.post(list.otpVerify, { otp });

export const serviceRequestOtp = (payload) =>
	api.post(list.otpRequest, payload);

export const servicePostRegisterProfile = ({
	email,
	education,
	income,
	income_source,
	bank,
	identity,
	bank_account_number,
	bank_account_name,
	file_identity,
	file_edd,
	file_signature,
	income_source_other,
}) =>
	api.post(list.registerSubmit, {
		email,
		education,
		income,
		income_source,
		bank,
		identity,
		bank_account_number,
		bank_account_name,
		file_identity,
		file_edd,
		file_signature,
		income_source_other,
	});
export const serviceSendCodeVerifyEmail = ({ token }) =>
	api.post(list.authVerify, { token });

export const serviceSendVerificationEmail = () =>
	api.post(list.emailVerification);

export const servicePostPinVerify = ({ pin }) =>
	api.post(list.pinVerify, { pin });

export const servicePostPinSetup = ({ pin, pin_confirm }) =>
	api.post(list.pinSetup, { pin, pin_confirm });

export const servicePostValidatePin = ({ pin }) => {
	return api.post(list.validatePin, { pin });
};

export const serviceLogoutUser = () => api.post(list.logout);

export const serviceFetchUserProfile = () => api.get(list.userProfile);

export const serviceUpdateStatusBar = ({ statusHide }) =>
	api.post(list.userPreferenceUpdate, { statusHide });

export const serviceRestoreInitialUser = serviceFetchUserProfile;

const serviceUserBankList = () => api.get(list.userBankList);
const serviceUserBankListComposed = responseAxiosHOF(serviceUserBankList);

const serviceUserBankRequest = () => api.post(list.userRequestChangeBank);
const serviceUserBankRequestComposed = responseAxiosHOF(serviceUserBankRequest);

const serviceUserBankValidateRequest = (token) =>
	api.post(list.userChangeBankValidateRequest, { token });
const serviceUserBankValidateRequestComposed = responseAxiosHOF(
	serviceUserBankValidateRequest
);

export {
	serviceUserBankListComposed as serviceUserBankList,
	serviceUserBankRequestComposed as serviceUserBankRequest,
	serviceUserBankValidateRequestComposed as serviceUserBankValidateRequest,
};
