import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { servicePostPinVerify } from "../../services/user.services";
import PinInput from "../Common/PinInput/PinInput";
import debounce from "lodash.debounce";
import { getPartnerStatus } from "../../entities/userpartner.selector";
import {
	getStatusLoadingSelector,
	isErrorSelector,
} from "../../services/apimanager/apimanager.selector";
import apiconstant from "../../services/apiconstant";
import getSafely from "../../utils/safely";
import { compose } from "redux";
import { connect } from "react-redux";
import { ActivityIndicator } from "antd-mobile";

/**
 * Function will translate result to determined if VerifyPin page
 * "is not visited" = 0,
 * "is forced goback" = 1,
 * "is already verified" = 2
 * @param {*} locationState
 */
export function parseVerifyPin(locationState = { pinPassed: null }) {
	const isVisited = typeof locationState.pinPassed === "boolean";

	if (!isVisited) return 0;

	return locationState.pinPassed ? 2 : 1;
}

class VerifyPin extends Component {
  getLocationState = () => this.props.location.state;

  componentDidMount() {
  	const { history } = this.props;

  	const locationState = this.getLocationState();

  	const unlisten = history.listen((newLocation, action) => {
  		if (action === "POP") {
  			// to listen back
  			history.replace({
  				pathname:
            locationState.from.pathname !== history.location.pathname
            	? locationState.from.pathname
            	: "/",
  				state: {
  					pinPassed: false,
  				},
  			});
  		}
  	});

  	/* 
			history not listen if componentWillUnmount executed unlisten, 
			needs delayed before its unlisten 
			https://github.com/ReactTraining/history/issues/710
		*/
  	this.unlisten = debounce(() => {
  		unlisten();
  	}, 0);
  }

  componentWillUnmount = () => {
  	this.unlisten();
  };

  handleSubmit = (pin) => {
  	const { history } = this.props;

  	const locationState = this.getLocationState();

  	return servicePostPinVerify({ pin }).then((response) => {
  		if (!response) {
  			return alert("response is empty");
  		}
  		return history.replace({
  			pathname: locationState.from.pathname,
  			state: {
  				pinPassed: true,
  			},
  		});
  	});
  };
  render() {
  	return (
  		<Fragment>
  			<div className="bit-link-title-reg">
  				<h5>Change Bank</h5>
  				<h1>Konfirmasi Pin Bibit</h1>
  			</div>
  			<PinInput
  				onSubmitPin={this.handleSubmit}
  				errorMessage={this.props.err_message}
  			/>
  			<ActivityIndicator
  				toast
  				text={"Permintaan sedang di proses"}
  				animating={this.props.isloading}
  			/>
  		</Fragment>
  	);
  }
}

const mapStateToProps = (state) => {
	const user_partner_status = getPartnerStatus(state);
	const isloading = getStatusLoadingSelector(state, [
		apiconstant.pinVerify,
		apiconstant.userProfile,
	]);

	const err =
    isErrorSelector(state, apiconstant.pinVerify) ||
    isErrorSelector(state, apiconstant.userProfile);

	const err_message = getSafely(["message"], err);

	return {
		isloading: isloading,
		err_message: err_message,
		isopen: user_partner_status === 3,
	};
};

const composed = compose(connect(mapStateToProps), withRouter);

export default composed(VerifyPin);
