/*
* @Author: stockbit6
* @Date:   2016-08-01 11:59:06
* @Last Modified by:   stockbit6
* @Last Modified time: 2018-09-06 11:17:54
*/

import moment from 'dayjs';
import duration from 'dayjs/plugin/duration';
import * as Sentry from '@sentry/browser';

import { recordEvent, recordCustomEvent } from './Analytics';
import Storage from './Storage';
import mime from 'mime-types';

import Api from './apimanager';
import list from './../services/apiconstant';
import getSafely from './safely';
import { generateRandomNumber } from '.';
import Compress  from 'compress.js';



export const LIMIT_UPLOAD_FILE = 20971520;


moment.extend(duration);
const api = new Api();

/**
 * Compress base64 Image
 *
 * @export base64ImageCompress
 * @param {string} base64Image
 * @returns 
 */
export async function base64ImageCompress(base64Image) {

	let _base64Compressed = '';

	try {

		let _blob = dataURItoBlob(base64Image);
		_base64Compressed = await compressImage2(_blob);
		return _base64Compressed;

	} catch (error) {


		return '';
	}


}


/**
 * Convert URI to BLOB
 *
 * @param {string} dataURI
 * @returns {blob}
 */
function dataURItoBlob(dataURI) {
	let content = dataURI.split(',')[1];
	let binary = atob(content);
	var array = [];
	for (var i = 0; i < binary.length; i++) {
		array.push(binary.charCodeAt(i));
	}
	return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

/**
 * Convert base64 string representation into image object
 * @param  {File}            file - The file to be converted
 * @return {Promise<String>}      - Base 64 string representation
 */
export async function base64ToImage(base64, needCompress = false) {

	const ext = getExtFromBase64(base64);

	let file_name = Date.now() + ext;
	let _blob = dataURItoBlob(base64);
	if (needCompress) {
		
		try {

			let _base64Compressed = await compressImage2(_blob);
			_blob = dataURItoBlob(_base64Compressed);


		} catch (e) {

			console.error(e);
			console.log('fallback to file');

		}
	}
	let file = new File([_blob], file_name);
	return file;
}

/**
 * Convert File object to Base64 string representation
 * @param  {File}            file - The file to be converted
 * @return {Promise<String>}      - Base 64 string representation
 */
export function fileToBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

/**
 * Generate image name
 *
 * @export
 * @param {string} formattype
 * @param {string} [prefix='stockbit']
 * @returns {string}
 */
export function formatUploadImageName(formattype, prefix = 'stockbit') {
	let randomString = generateRandomNumber()
		.toString(36)
		.substr(2, 10);
	let now = moment().format();
	now = now.replace(/-/g, '');
	now = now.replace(/:/g, '');
	now = now.substr(0, 15);
	return prefix + randomString + now + 'web.' + formattype;
}

/**
 * Generate file name
 *
 * @export
 * @param {string} file
 * @returns
 */
export function formatUploadFileName(file) {
	try {
		let extension = file.slice(((file.lastIndexOf('.') - 1) >>> 0) + 2); // Returning only file extension, safeguarding from 'filename' or '.filename'
		let indexSeparator = file.lastIndexOf('.');
		let filename = indexSeparator < 0?file: file.substr(0, file.lastIndexOf('.'));
		let randomString = generateRandomNumber()
			.toString(36)
			.substr(2, 10);
		let d = new Date();
		let currDate = String('00' + d.getDate()).slice(-2); // Get today's date with leading zero
		let currMonth = String('00' + (d.getMonth() + 1)).slice(-2); // Get today's month with leading zero
		let currYear = d.getFullYear();
		let output = convertToSlug(
			filename + ' ' + randomString + currYear + currMonth + currDate,
		);
		
		if(extension) 
			output += '.' + extension;
		
		return output;
		
	} catch (error) {
		console.error(error);
	}

}

/**
 * Convert string to slug 
 *
 * @export
 * @param {string} text
 * @returns
 */
export function convertToSlug(text) {
	if (!text) return text;
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w-]+/g, '') // Remove all non-word chars
		.replace(/--+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
}

async function __seekCredential() {
	return await Storage.getObject('ac', 'access');
}

export async function createBase64UploadPromiseFallback (filename = null, file = '', needCompress = false, options = null) {

	let generateFilename = false;

	let thefile = file;
	if (!file) {
		thefile = filename;
		// file = filename;
		generateFilename = true;
	}

	if (!thefile) {
		return await '';
	}

	// let _file = null;
	let newFileName = '';

	if (generateFilename) {
		// Generate unique filename
		let fileName = 'identity.jpeg';
		newFileName = formatUploadFileName(fileName);
	} else {
		// not generate
		newFileName = filename;
	}

	let _resolve = null;
	let _reject = null;

	const PromiseOutput = new Promise((resolve, reject) => {
		_resolve = resolve;
		_reject = reject;
	});

	if (options && options.uploadfor && options.code) {
		const PuploadImageFallback = uploadImageFallback({
			file: file,
			key: newFileName,
			item: options.uploadfor,
			item_id: options.code
		}, { needCompress });
		PuploadImageFallback
			.then(result => {
				if (!result) {

					throw new Error('Something is wrong, Please try again later.');
				}
				_resolve(result);
			})
			.catch(err => {

				_reject(err);
			});
	} else {
		_reject('cannot found uploadfor');
	}

	return PromiseOutput;

} 

/**
 * Config Option S3
 * @typedef {Object} Option
 * @property {string} uploadfor - Target purpose of upload
 * @property {string} code - Target unique code
 */

/**
 * Upload base64 to s3
 *
 * @export
 * @param {*} [filename=null]
 * @param {string} [file='']
 * @param {boolean} [needCompress=false]
 * @param {Option} [options=null]
 * @returns
 */
export async function createBase64UploadPromise(filename = null, file = '', needCompress = false, options = null) {

	let generateFilename = false;

	let thefile = file;
	if (!file) {
		thefile = filename;

		generateFilename = true;
	}

	if (!thefile) {
		return await '';
	}


	let newFileName = '';
	let ext = getExtFromBase64(thefile);

	if (generateFilename) {
		// Generate unique filename
		let fileName = 'identity'+ext;
		newFileName = formatUploadFileName(fileName);
	} else {
		// not generate
		newFileName = filename+ext;
	}

	let _resolve = null;
	let _reject = null;

	const PromiseOutput = new Promise((resolve, reject) => {
		_resolve = resolve;
		_reject = reject;
	});

	let _file = await base64ToImage(thefile, needCompress);
	console.log('options', options);
	const PuploadImageAvatar = uploadImageAvatar(_file, newFileName, options);

	PuploadImageAvatar
		.then(result => {

			if (!result) {

				recordCustomEvent({
					event: "bibit.log",
					eventAction: "window.error",
					eventLabel: "result_upload_isempty"
				});
				throw new Error('Something is wrong, Please try again later.');
			}


			_resolve(result);
		})
		.catch(error => {

			recordEvent({
				page: 'javascript',
				path: 'error_after_network',
				param_amp: {
					description: error, 
					options
				}
			});

			recordCustomEvent({
				event: "bibit.log",
				eventAction: "window.error",
				eventLabel: "error_after_network"
			});

			if (options && options.uploadfor && options.code) {
				


				const PuploadImageFallback = uploadImageFallback({
					file: file,
					key: newFileName,
					item: options.uploadfor,
					item_id: options.code
				}, { needCompress });
				PuploadImageFallback
					.then(result => {
						if (!result) {

							throw new Error('Something is wrong, Please try again later.');
						}
						_resolve(result);
					})
					.catch(err => {

						_reject(err);
					});
			} else {

			}

		});

	return PromiseOutput;

}

/**
 * 
 * @param {*} type :: type: path_in_aws
 * 					  ===========================================
 * 					  ktp: 'registrations',
             		  signature: 'registrations',
             		  edd: 'edd',
             		  bank_selfie: 'bankaccount',
             		  bank_account: 'bankaccount',
             		  payment_receipt: 'orders/confirmations'
 * @param {*} accessToken 
 */
function generateTokenUploadV2(type, accessToken = null) {
	
	/*
	{
		"message": "Token generated",
		"data": {
			"folder": "edd/165408/",
			"key": "",
			"AWSAccessKeyId": "xxxxx",
			"success_action_redirect": "https://linkaja.apilab.bibit.id/default/tools/s3/callback",
			"policy": "eyJleHBpcmF0aW9uIjogIjIwMjAtMDItMTdUMTU6MTY6NDhaIiwiY29uZGl0aW9ucyI6IFt7ImJ1Y2tldCI6ICJiaWJpdGRldiJ9LFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICJlZGQvMTY1NDA4LyJdLHsiYWNsIjogInByaXZhdGUifSxbInN0YXJ0cy13aXRoIiwgIiRDb250ZW50LVR5cGUiLCAiaW1hZ2UvIl0seyJzdWNjZXNzX2FjdGlvbl9yZWRpcmVjdCI6ICJodHRwczovL2xpbmthamEuYXBpbGFiLmJpYml0LmlkL2RlZmF1bHQvdG9vbHMvczMvY2FsbGJhY2sifV19",
			"signature": "hYdkQWHbYt9LmogX6dY/Zs4VDCU=",
			"Content-Type": "image/"
		}
	}
	*/
	
	return new Promise((resolve, reject) => {
		let availableType = ["ktp", "edd", "signature", "bank_selfie", "bank_account", "payment_receipt"];
		
		if(!availableType.includes(type)) {
			return reject("Please check your Type Upload file");
		}
		
		let generateURL;
		if(accessToken === null) {	
			generateURL = list.awsTokenV2 + `?type=${type}`;
		} else {
			generateURL = list.awsTokenV2NoAuth + `?type=${type}&verification_token=${accessToken}`; 
		}
		
		return api.get(generateURL).then(res => {
			return resolve(res);
		}).catch(err => {
			Sentry.configureScope(function(scope) {
				scope.setExtra("service_error", 'AWS TOKEN');
				scope.setExtra("the_error", err);
			});
			return reject(err);
		});
	});
	
}


/**
 * Compress the image
 *
 * @param {*} file
 * @returns
 */
function compressImage2(file) {
	const compress = new Compress();
	const options = {
		size: .7, // the max size in MB, defaults to 2MB
		quality: .75, // the quality of the image, max is 1,
		maxWidth: 1920, // the max width of the output image, defaults to 1920px
		maxHeight: 1920, // the max height of the output image, defaults to 1920px
		// resize: true, // defaults to true, set false if you do not want to resize the image width and height
	};
	return compress.compress([file], options).then(([compressed]) => {
		let output = '';
		if (compressed) {
			const {
				data,
				prefix
			} = compressed;
			output = prefix + data;
		}
		return (output);
	});
}

/**
 * Convert bytes to human readable
 *
 * @param {*} fileSizeInBytes
 * @returns
 */
function getReadableFileSizeString(fileSizeInBytes) {
	var i = -1;
	var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
	do {
		fileSizeInBytes = fileSizeInBytes / 1024;
		i++;
	} while (fileSizeInBytes > 1024);

	return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

/**
 * 
 * @param {string} base64 :: data:image/jpeg;........
 * @returns {string} contentType
 */
function getContentTypeFromBase64(base64) {
	const split1 = base64.split(":");
	const split2 = split1[1].split(";");
	return split2[0];
}

/**
 * 
 * @param {string} base64 :: data:image/jpeg;........
 */
export function getExtFromBase64(base64) {
	let contentType = getContentTypeFromBase64(base64);
	let extSplit = contentType.split("/");
	return "." + extSplit[1];
}

/**
 * Upload image avatar
 *
 * @export
 * @param {*} file
 * @param {*} [newfilename=null]
 * @returns
 */
export async function uploadImageAvatar(file, newfilename = null, options = null) {

	/*
		{
			"message": "Token genearted",
			"data": {
				"key": "",
				"AWSAccessKeyId": "xxxxxxx",
				"policy": "eyJleHBpcmF0aW9uIjoiMjAyNS0wNy0zMVQwMDowMDowMFoiLCJjb25kaXRpb25zIjpbeyJidWNrZXQiOiJzdG9ja2JpdCJ9LHsiYWNsIjoicHVibGljLXJlYWQifSxbImNvbnRlbnQtbGVuZ3RoLXJhbmdlIiwwLDIwMDAwMDBdXX0=",
				"signature": "RScygc34Igvac0th28klYWlI+qw=",
				"Content-Type": "image/jpg"
			}
		}
	*/

	const typeUpload = getSafely(["uploadfor"], options, "");
	const tokenCode = getSafely(["token"], options, null);

	return generateTokenUploadV2(typeUpload, tokenCode).then(resp => {

		let token = null;

		if (resp) {
			token = getSafely(['data', 'data'], resp, {});
		}else{
			throw new Error(`response from ${list.awsTokenV2} is empty`);
		}

		let fd = new FormData();
		let former_file = null;

		if (file._file) former_file = file._file;

		let _acl = '';

		/* dynamic read from api */
		try {
			let temp = null;
			temp = atob(token['policy']);
			temp = JSON.parse(temp);
			if (temp.conditions && typeof temp.conditions.map === 'function' && temp.conditions.length > 0)
				temp.conditions.map(item => {
					if (item['acl']) {
						_acl = item['acl'];
					}
					if (item['bucket']) {
						_acl = item['bucket'];
					}
					return item;
				});
		} catch (error) {
			console.error(error);

		}
 
		if (!_acl)
			_acl = 'public-read';


		const contentType = mime.contentType(getSafely(["name"], file, ""));

		fd.append('key', token['folder'] + newfilename);
		fd.append('AWSAccessKeyId', token['AWSAccessKeyId']);
		fd.append('acl', _acl);
		fd.append('success_action_redirect', token['success_action_redirect']);

		fd.append('policy', token['policy']);
		fd.append('signature', token['signature']);
		fd.append('Content-Type', contentType);

		let size = '0 kB';
		if (!former_file) {
			size = (file) ? getReadableFileSizeString(file.size) : '';
			console.log('file size : ', size);
			fd.append('file', file);
		} else {
			size = (former_file) ? getReadableFileSizeString(former_file.size) : '0 kB';
			console.log('file size : ', size);
			fd.append('file', former_file);
		}

		return api.postFile(list.awsBucket, fd);

	}).catch(err => {
		console.log('err', err);
	});
}

/**
 * definition paramsImageFallback
 * @typedef {Object} ParamsImageFallback
 * @property {string} file - Base64 String
 * @property {string} key - Key code path s3
 * @property {string} item - Purpose upload enum
 * @property {string} item_id - Unique code upload target
 */

/**
 * Fallback upload Image to API
 *
 * @param {ParamsImageFallback} paramsImageFallback params image 
 * @param {Object} options upload image fallback option
 * @param {Boolean} options.needCompress decide if image need to be compressed
 * @returns
 */
async function uploadImageFallback(paramsImageFallback, opt) {

	let needCompress = false;

	// If need compress variable is not passed, default to true
	if(opt || opt.needCompress === null || opt.needCompress === undefined) {
		needCompress = true;
	}

	// const stopRecorder = createRecordTime();

	let ac = await __seekCredential();
	const options = {
		headers: {
			'content-type': 'application/json',
		},
	};
	if (ac) ac = 'Bearer ' + ac;
	if (ac) options.headers['Authorization'] = ac;

	const {
		file,
		key,
		item,
		item_id
	} = paramsImageFallback;

	let _blob = dataURItoBlob(file);
	let file_compressed = file;
	if (needCompress) {
		file_compressed = await compressImage2(_blob);
	}

	let body = {
		file: file_compressed,
		key,
		item,
		item_id,
	};

	return api.postFile(list.fallback_upload, body, options)
		.catch((error) => {
			// Error
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				// _message = [
				// 	'Data: ' + error.response.data,
				// 	'Status: ' + error.response.status,
				// 	'Headers: ' + error.response.headers,
				// 	'Error object: ' + JSON.stringify(error)
				// ].join(' - ');

			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}


		});
}
