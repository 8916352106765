import React, { Component, Fragment } from 'react';
import './PinInput.css';

import DeleteKey from './../../../assets/images/delete-key.svg';
import { Eye, EyeOff } from 'react-feather';

class PinInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPinSubmit: props.isPinSubmitDefault,
			pin: [],
			showPassword: false,
		};
		this.pinInputRef = React.createRef();
	}
	componentDidUpdate(prevProps, prevState) {
		const { errorMessage: _errMessage } = prevProps;

		const { errorMessage } = this.props;

		if (errorMessage !== _errMessage && errorMessage) {
			if (this.state.isPinSubmit) {
				this.clearPin();
			}
		}
	}

  onToggleShowPassword = () => {
  	this.setState({
  		showPassword: !this.state.showPassword,
  	});
  };

  handleChangePin = (num) => {
  	const { pin } = this.state;
  	const { onChangePin } = this.props;

  	if (this.state.isPinSubmit)
  		this.setState({
  			isPinSubmit: false,
  		});
  	this.setState({
  		pin: [...pin, num],
  	});

  	const formattedPin = pin.join('') + num; //convert to string
  	onChangePin(formattedPin);
  };
  clearPin = () => {
  	this.setState({
  		pin: [],
  	});
  };
  handleDeletePin = () => {
  	const { onChangePin } = this.props;

  	let newArray = [...this.state.pin];
  	newArray.pop();
  	this.setState({
  		pin: newArray,
  	});

  	const formattedPin = newArray.join('');
  	onChangePin(formattedPin);
  };

  submitThePin = async (pin) => {
  	const { onSubmitPin } = this.props;
  	try {
  		const res = await onSubmitPin(pin);
  		if (res?.isError) {
  			this.clearPin();
  		}
  	} catch (error) {
  		this.clearPin();
  	}
  	this.setState({
  		isPinSubmit: true,
  	});
  };
  renderDot = () => {
  	const { descLabel, errorMessage, isPinCreation } = this.props;
  	const { isPinSubmit, pin, showPassword } = this.state;
  	return (
  		<div className='bit-link-new-pin'>
  			<div className='bit-new-pin'>
  				<div
  					className={`pin-input-wrapper ${
  						isPinSubmit && errorMessage ? 'error' : ''
  					}`}
  				>
  					<div
  						className={`pin-input-content ${
  							isPinCreation ? 'hasToggle' : ''
  						}`}
  					>
  						<input
  							ref={this.pinInputRef}
  							inputMode='none'
  							className={`pin-text ${
  								!showPassword && pin.length > 0 ? 'pin-text-hide' : ''
  							}`}
  							type='text'
  							readOnly={pin.length > 0}
  							value={pin.join('')}
  							placeholder={isPinCreation ? 'Minimum berisi 6 digit' : ''}
  						/>
  						{!showPassword && (
  							<div className='pin-dot-wrapper'>
  								{pin.map((e, i) => (
  									<div className='pin-dot' key={i} />
  								))}
  							</div>
  						)}
  					</div>
  					{isPinCreation && (
  						<div className='pin-toggle-password'>
  							{showPassword ? (
  								<Eye onClick={this.onToggleShowPassword} />
  							) : (
  								<EyeOff onClick={this.onToggleShowPassword} />
  							)}
  						</div>
  					)}
  				</div>
  			</div>
  			<br />
  			<div className='errorspan' style={{ marginTop: '14px' }}>
  				{isPinSubmit && errorMessage}
  			</div>
  			<div className='bit-pin-desc'>{descLabel}</div>
  		</div>
  	);
  };

  renderSubmitButton = () => {
  	const { pin } = this.state;
  	const { isPinCreation } = this.props;

  	return (
  		<div className='bit-button-submit-wrapper'>
  			<button
  				className='bit-button-submit'
  				disabled={
  					(isPinCreation && pin.length < 6) ||
            (!isPinCreation && pin.length < 4)
  				}
  				onClick={() => this.submitThePin(pin.join(''))}
  			>
          Lanjut
  			</button>
  		</div>
  	);
  };

  renderKeyboard = () => {
  	return (
  		<div className='bit-custom-keyboard'>
  			<div onClick={() => this.handleChangePin(1)}>1</div>
  			<div onClick={() => this.handleChangePin(2)}>2</div>
  			<div onClick={() => this.handleChangePin(3)}>3</div>
  			<div onClick={() => this.handleChangePin(4)}>4</div>
  			<div onClick={() => this.handleChangePin(5)}>5</div>
  			<div onClick={() => this.handleChangePin(6)}>6</div>
  			<div onClick={() => this.handleChangePin(7)}>7</div>
  			<div onClick={() => this.handleChangePin(8)}>8</div>
  			<div onClick={() => this.handleChangePin(9)}>9</div>
  			<div></div>
  			<div onClick={() => this.handleChangePin(0)}>0</div>
  			<div onClick={this.handleDeletePin}>
  				<img src={DeleteKey} alt='delete' />
  			</div>
  		</div>
  	);
  };
  render() {
  	return (
  		<Fragment>
  			{this.renderDot()}
  			<div className='bit-bottom-wrapper'>
  				{this.renderSubmitButton()}
  				{this.renderKeyboard()}
  			</div>
  		</Fragment>
  	);
  }
}

PinInput.defaultProps = {
	//action
	onBack: () => {},
	onSubmitPin: async () => {},
	onChangePin: async () => {},
	pin: [],
	isPinSubmitDefault: false,
	isPinCreation: false,
};

export default PinInput;
