import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import getSafely from './../../../utils/safely';
import parseQuery from './../../../utils/parseQuery';

import LogoBibit from './../../../assets/images/logo-bibit.svg';

import PinInput from '../../Common/PinInput/PinInput';

import {
	isErrorSelector,
	isLoadingSelector,
} from './../../../services/apimanager/apimanager.selector';

import ActivityIndicator from 'antd-mobile/lib/activity-indicator';

import {
	postSubmitPin,
	postCheckPin,
	postValidatePin,
} from './ViewInputPin.reducer';

import list from './../../../services/apiconstant';
import GlobalHeader from '../../Global/GlobalHeader/GlobalHeader';

class ViewInputPin extends Component {
  state = {
  	querystring: null,
  	data: {},
  	step: 0,
  	isSubmit: false,
  	localErrMessage: '',
  };
  componentDidMount() {
  	const {
  		location: { search },
  		status,
  		history,
  		pin,
  	} = this.props;

  	if (status >= 1 && pin > 0 && this.isRegister())
  		return history.push('/index-home');

  	const _search = parseQuery(search);
  	this.setState({
  		querystring: _search,
  	});
  }
  componentDidUpdate(prevProps) {
  	const { err } = this.props;
  	const type = getSafely(['data', 'type'], err);
  	if (type === 'failed_pin_already_setup') {
  		const { history } = this.props;
  		history.push('/success-connected');
  		setTimeout(() => {
  			history.push('/index-home');
  		}, 3000);
  	}
  }

  checkPinFormat = (pin) => {
  	if (pin.length < 6) {
  		return 'PIN minimal berisi 6 digit';
  	}

  	if (pin.length > 12) {
  		return 'PIN maksimal berisi 12 digit';
  	}

  	const isSequential = (digits) => {
  		let ascending = true;
  		let descending = true;

  		for (let i = 1; i < digits.length; i++) {
  			if (digits[i] !== digits[i - 1] + 1) {
  				ascending = false;
  			}
  			if (digits[i] !== digits[i - 1] - 1) {
  				descending = false;
  			}
  			if (!ascending && !descending) break;
  		}

  		return ascending || descending;
  	};

  	// Check sequences
  	const digits = pin.split('').map(Number);
  	if (isSequential(digits)) {
  		return 'PIN tidak boleh berupa angka berurutan';
  	}

  	const isAllSameDigits = digits.every((digit) => digit === digits[0]);
  	if (isAllSameDigits) {
  		return 'PIN tidak boleh berupa angka berulang';
  	}

  	// PIN passed all validations
  	return null;
  };

  handleOnChangePin = () => {
  	this.setState({
  		...this.state,
  		localErrMessage: '',
  	});
  };

  isRegister = () => {
  	const { querystring } = this.state;
  	const register = getSafely(['register'], querystring);
  	return register === 'true';
  };
  handleSubmit = async (pin) => {
  	if (this.isRegister()) {
  		const { pin: _pin } = this.state.data;
  		if (_pin !== pin) {
  			const errMsg = 'PIN yang kamu masukkan berbeda dengan yang kamu buat';
  			this.setState({
  				data: {
  					...this.state.data,
  					confirmpin: '',
  				},
  				localErrMessage: errMsg,
  			});

  			return {
  				isError: true,
  				message: errMsg,
  			};
  		}

  		this.setState(
  			{
  				data: {
  					...this.state.data,
  					confirmpin: pin,
  				},
  				isSubmit: true,
  			},
  			() => {
  				const { data } = this.state;

  				const { pin: _pin, confirmpin } = data;

  				return this.props.postSubmitPin(_pin, confirmpin).catch(() => {
  					this.setState({
  						data: {},
  						step: 0,
  					});
  				});
  			}
  		);
  	} else {
  		this.setState(
  			{
  				data: {
  					...this.state.data,
  					pin: pin,
  				},
  			},
  			() => {
  				const { data } = this.state;
  				const { pin: _pin } = data;
  				return this.props.postCheckPin(_pin).catch(() => {
  					this.setState({
  						data: {},
  						// step: 0,
  					});
  				});
  			}
  		);
  	}
  };
  stephandleSubmit = async (pin) => {
  	const error = this.checkPinFormat(pin);
  	if (error) {
  		this.setState({
  			data: {
  				...this.state.data,
  				pin: '',
  			},
  			localErrMessage: error,
  		});

  		return {
  			isError: true,
  			message: error,
  		};
  	} else {
  		return this.props
  			.postValidatePin(pin)
  			.then(() => {
  				this.setState({
  					data: {
  						...this.state.data,
  						pin: pin,
  					},
  					step: 1,
  					isSubmit: false,
  				});
  			})
  			.catch((err) => {
  				const errMsg =
            err?.response?.data?.message ||
            'Terjadi kesalahan! periksa kembali data yang Kamu masukan.';

  				this.setState({
  					data: {},
  					step: 0,
  					localErrMessage: errMsg,
  				});

  				return {
  					isError: true,
  					message: errMsg,
  				};
  			});
  	}
  };
  renderCheckPin = () => {
  	return (
  		<Fragment>
  			<div className='bit-link-title-reg'>
  				<h5>Reksa Dana</h5>
  				<h1>{'Masukan Pin Bibit'}</h1>
  			</div>
  			<PinInput
  				key={this.state.step}
  				onSubmitPin={this.handleSubmit}
  				errorMessage={this.props.err_message}
  			/>
  		</Fragment>
  	);
  };
  renderRegisterPin = () => {
  	return (
  		<Fragment>
  			<div className='bit-link-title-reg'>
  				<h5>Reksa Dana</h5>
  				<h1>
  					{this.state.step === 0 ? 'Buat Pin Bibit' : 'Konfirmasi Pin Bibit'}
  				</h1>
  			</div>
  			<PinInput
  				key={this.state.step}
  				onSubmitPin={
  					this.state.step === 0 ? this.stephandleSubmit : this.handleSubmit
  				}
  				errorMessage={this.state.localErrMessage || this.customErrorMessage()}
  				isPinSubmitDefault={this.state.isSubmit}
  				onChangePin={this.handleOnChangePin}
  				isPinCreation={true}
  			/>
  		</Fragment>
  	);
  };

  /**
   * Use this function to customizing error message
   * or update error message from BE response
   */
  customErrorMessage = () => {
  	const currentErrorMessage = this.props.err_message;
  	if (
  		this.isRegister() &&
      currentErrorMessage ===
        'Terjadi kesalahan! periksa kembali data yang Kamu masukan.'
  	) {
  		return 'Konfirmasi PIN salah';
  	}
  	return currentErrorMessage;
  };

  render() {
  	// const {
  	// 	onBack,
  	// 	renderOnBack,
  	// 	loading,
  	// 	title,
  	// 	headerText,
  	// 	descLabel,
  	// 	showSupport,
  	// 	errorMessage,
  	// } = this.props;

  	const { isloading } = this.props;
  	return (
  		<div>
  			<div className='bit-link-wrap'>
  				<div className='bit-link-header'>
  					<GlobalHeader />
  				</div>
  				<div className='bit-link-register'>
  					{this.isRegister()
  						? this.renderRegisterPin()
  						: this.renderCheckPin()}
  					<ActivityIndicator toast text='Loading...' animating={isloading} />
  				</div>
  				<div className='bit-link-footer'>
  					<div>Powered by :</div>
  					<div>
  						<img src={LogoBibit} alt='logo bibit' />
  					</div>
  				</div>
  			</div>
  		</div>
  	);
  }
}

const mapStateToProps = (state) => {
	const isloading =
    isLoadingSelector(state, list.pinSetup) ||
    isLoadingSelector(state, list.pinCheck);
	const err =
    isErrorSelector(state, list.pinSetup) ||
    isErrorSelector(state, list.pinCheck);
	let err_message = getSafely(['message'], err);
	if (!err_message) {
		err_message = '';
	}
	return {
		isloading: isloading,
		err: err,
		err_message,

		status: getSafely(['userpartner', 'user_partner_status'], state),
		pin: getSafely(['user', 'profile', 'pin'], state),
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			postSubmitPin,
			postCheckPin,
			postValidatePin,
		},
		dispatch
	);

const composed = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
);

export default composed(ViewInputPin);
